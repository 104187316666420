<template>
  <div class="carousel-3d-controls">
    <button
      type="button"
      class="prev"
      :class="{ disabled: !parent.isPrevPossible }"
      :style="`width: ${width}px; height: ${height}px; line-height: ${height}px;`"
      aria-label="Previous slide"
      :disabled="!parent.isPrevPossible"
      @click="parent.goPrev()"
      @keydown.left="parent.goPrev()"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="sanitizedPrevHtml" />
    </button>
    <button
      type="button"
      class="next"
      :class="{ disabled: !parent.isNextPossible }"
      :style="`width: ${width}px; height: ${height}px; line-height: ${height}px;`"
      aria-label="Next slide"
      :disabled="!parent.isNextPossible"
      @click="parent.goNext()"
      @keydown.right="parent.goNext()"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="sanitizedNextHtml" />
    </button>
  </div>
</template>

<script>
import DOMPurify from 'dompurify'

export default {
  name: 'Controls',
  props: {
    /**
     * Width in pixels of the navigation buttons
     */
    width: {
      type: [String, Number],
      default: 50
    },
    /**
     * Height in pixels of the navigation buttons
     */
    height: {
      type: [String, Number],
      default: 60
    },
    /**
     * Text content of the navigation prev button
     */
    prevHtml: {
      type: String,
      default: '&lsaquo;'
    },
    /**
     * Text content of the navigation next button
     */
    nextHtml: {
      type: String,
      default: '&rsaquo;'
    }
  },
  data () {
    return {
      parent: this.$parent
    }
  },
  computed: {
    /**
     * Sanitized version of prevHtml
     */
    sanitizedPrevHtml () {
      return DOMPurify.sanitize(this.prevHtml)
    },
    /**
     * Sanitized version of nextHtml
     */
    sanitizedNextHtml () {
      return DOMPurify.sanitize(this.nextHtml)
    }
  }
}
</script>

<style scoped>
.carousel-3d-controls {
  position: absolute;
  top: 50%;
  height: 0;
  margin-top: -30px;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.next, .prev {
  width: 60px;
  position: absolute;
  z-index: 1010;
  font-size: 60px;
  height: 60px;
  line-height: 60px;
  color: #333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  top: 0;
}

.next:hover, .prev:hover {
  cursor: pointer;
  opacity: 0.7;
}

.prev {
  left: 10px;
  text-align: left;
}

.next {
  right: 10px;
  text-align: right;
}

.disabled {
  opacity: 0.2;
  cursor: default;
}

.disabled:hover {
  cursor: default;
  opacity: 0.2;
}
</style>
