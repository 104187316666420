<template>
  <div
    class="outer-box"
    :style="cssProps"
  >
    <v-btn
      v-if="showControls"
      icon
      elevation="2"
      class="arrow arrow-left"
      @click="goToSlidePrev"
    >
      <v-icon>
        mdi-chevron-left
      </v-icon>
    </v-btn>
    <v-btn
      v-if="showControls"
      icon
      elevation="2"
      class="arrow arrow-right"
      @click="goToSlideNext"
    >
      <v-icon>
        mdi-chevron-right
      </v-icon>
    </v-btn>

    <Carousel3d
      :ref="`carousel-${part}`"
      :width="dimensions.width"
      :height="dimensions.height"
      class="carousel"
      perspective="0"
      :space="carouselSpace"
      border="0"
    >
      <Slide
        v-for="(choice, i) in choices"
        :key="i"
        :index="i"
        class="main-box"
      >
        <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
          <component
            :is="getComponent(part)"
            :data-index="index"
            :style="currentSliderStyles(isCurrent)"
            :current-width="dimensions.width"
            :recalculate-height="recalculateHeight"
            :class="{
              onLeft: (leftIndex >= 0),
              onRight: (rightIndex >= 0)}"
            :content="choice"
            :is-markdown="shouldParseMarkdown"
            :is-loading="isLoading"
            @handleClick="handleClick(index, isCurrent, getComponent(part))"
            @currentHeight="calculateCurrentHeight($event, isCurrent)"
          />
        </template>
      </Slide>
    </Carousel3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from '@/components/carousel'
import TextBox from './Box/TextBox.vue'
import ImageBox from './Box/ImageBox.vue'
import KindType from './enums/KindType'
import { breakpointXsAndDown } from '@/lib/mobile'
import EditContent from '@/modules/contentCreator/creator/EditContent.vue'

export default {
  components: {
    EditContent,
    Carousel3d,
    Slide,
    TextBox,
    ImageBox
  },
  props: {
    kind: {
      type: String,
      default: ''
    },
    part: {
      type: String,
      default: ''
    },
    choices: {
      type: Array,
      default: () => []
    },
    hasAtLeastOneImage: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentHeight: null,
      contentForEditing: null,
      recalculateHeight: null
    }
  },
  computed: {
    hasTextAboveImage () {
      return this.isStructureTypeTextAboveImage && this.hasTextForKind && this.hasAtLeastOneImage
    },
    hasImageBeyondText () {
      return this.isStructureTypeTextAboveImage && this.hasImageForKind
    },
    hasImageAboveText () {
      return this.isStructureTypeImageAboveText && this.hasImageForKind
    },
    hasTextBeyondImage () {
      return this.isStructureTypeImageAboveText && this.hasTextForKind && this.hasAtLeastOneImage
    },
    isStructureTypeTextAboveImage () {
      const types = [
        KindType.facebookPost,
        KindType.linkedinPost,
        KindType.facebookAds,
        KindType.gmbNewsPost,
        KindType.gmbProductDescription
      ]
      return types.includes(this.kind)
    },
    isStructureTypeImageAboveText () {
      const types = [KindType.instagramPost, KindType.websiteText]
      return types.includes(this.kind)
    },
    showControls () {
      return this.choices.length > 1
    },
    cssProps () {
      const divider = this.breakpointXsAndDown() ? 2.4 : 2

      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary,
        '--slide-width-negative': `-${this.dimensions.width / divider}px`,
        '--slide-width': `${this.dimensions.width / divider}px`
      }
    },
    hasImageForKind () {
      return this.part === 'image' && this.choices?.length > 0
    },
    hasTextForKind () {
      return this.part === 'text' && this.choices?.length > 0
    },
    carouselSpace () {
      return KindType[this.kind] === KindType.websiteText ||
        KindType[this.kind] === KindType.customerMailing ||
        KindType[this.kind] === KindType.interview ||
        KindType[this.kind] === KindType.blogPost ? 680 : 490
    },
    dimensions () {
      const textDimensions = {
        width: this.breakpointXsAndDown() ? window.innerWidth - 20 : 400,
        height: this.currentHeight + 70
      }

      const imageDimensions = this.hasImageForKind ? { width: 400, height: 350 } : { width: 0, height: 0 }

      if (KindType[this.kind] === KindType.websiteText ||
        KindType[this.kind] === KindType.customerMailing ||
        KindType[this.kind] === KindType.interview ||
        KindType[this.kind] === KindType.blogPost) {
        textDimensions.width = this.breakpointXsAndDown() ? window.innerWidth - 20 : 800
        if (this.hasImageForKind) {
          imageDimensions.width = 800
          imageDimensions.height = 350
        }
      }

      return {
        image: imageDimensions,
        text: textDimensions
      }[this.part]
    },
    shouldParseMarkdown () {
      const markdownTypes = [KindType.facebookAds, KindType.websiteText, KindType.interview, KindType.blogPost]
      return this.part === 'text' && markdownTypes.includes(this.kind)
    }
  },

  methods: {
    calculateCurrentHeight (height, isCurrent) {
      if (isCurrent) { this.currentHeight = height }
    },
    currentSliderStyles (isCurrent) {
      if (!isCurrent) {
        return {
          border: '2px solid ' + this.$vuetify.theme.themes.light.primary
        }
      }

      const styles = {
        border: '5px solid ' + this.$vuetify.theme.themes.light.primary
      }
      if (this.hasTextAboveImage || this.hasImageAboveText) {
        styles['border-bottom'] = 'none'
        styles['border-top'] = '5px solid ' + this.$vuetify.theme.themes.light.primary
      }

      if (this.hasTextBeyondImage || this.hasImageBeyondText) {
        styles['border-top'] = 'none'
        styles['border-bottom'] = '5px solid ' + this.$vuetify.theme.themes.light.primary
      }

      return styles
    },
    breakpointXsAndDown,
    goToSlideNext () {
      this.recalculateHeight = this.$refs[`carousel-${this.part}`].currentIndex
      this.$refs[`carousel-${this.part}`].goNext()
      this.$emit('change', { part: this.part, content: this.choices[this.$refs[`carousel-${this.part}`].currentIndex] })
    },
    goToSlidePrev () {
      this.recalculateHeight = this.$refs[`carousel-${this.part}`].currentIndex
      this.$refs[`carousel-${this.part}`].goPrev()
      this.$emit('change', { part: this.part, content: this.choices[this.$refs[`carousel-${this.part}`].currentIndex] })
    },
    getComponent (part) {
      return {
        text: 'TextBox',
        title: 'TextBox',
        image: 'ImageBox'
      }[part]
    },
    handleClick (index, isCurrent, part) {
      if (isCurrent && part === 'TextBox') {
        this.$emit('openEditTextDialog', index)
        return
      }
      this.$emit('change', { part: this.part, content: this.choices[index] })
    }
  }
}
</script>

<style scoped>
.carousel {
  margin: -10px auto !important;
}

.main-box {
  border-radius: 5px;
  background-color: white;
  z-index: 4;
}

.outer-box{
  position: relative;
}

.arrow{
  position: absolute;
  color: var(--primary-color);
  cursor: pointer;
  z-index: 2;
  background-color: white;
  border-radius: 50px;
  border-color: var(--primary-color);
  border-width: 2px;
  border-style: solid;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  margin: auto;
}
.arrow-left{
  transform: translateX(var(--slide-width-negative));
}

.arrow-right{
  transform: translateX(var(--slide-width));
}

@media (max-width: 600px) {
  .arrow-left, .arrow-right {
    border: none;
    background: transparent;
  }
}
</style>
