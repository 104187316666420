import { render, staticRenderFns } from "./SavedContentTimeline.vue?vue&type=template&id=4d474939&scoped=true&"
import script from "./SavedContentTimeline.vue?vue&type=script&lang=js&"
export * from "./SavedContentTimeline.vue?vue&type=script&lang=js&"
import style0 from "./SavedContentTimeline.vue?vue&type=style&index=0&id=4d474939&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d474939",
  null
  
)

export default component.exports