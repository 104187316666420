<template>
  <div :style="cssProps">
    <DeleteScheduledPost
      v-if="deleteScheduledPostDialog"
      :content="contentForDelete"
      @postDeleted="$emit('savedScheduledContent')"
      @close="deleteScheduledPostDialog = false"
    />
    <v-tab-item :value="filterValue">
      <BlurredBackdropUpselling
        :is-blurred="disabled"
      >
        <v-timeline
          :dense="$vuetify.breakpoint.smAndDown"
        >
          <v-timeline-item
            v-for="content in savedContents"
            :key="content.id"
            class="v-timeline-item"
          >
            <template
              v-if="contentType === ContentType.SAVED_CONTENT"
              v-slot:icon
            >
              <v-avatar>
                <v-img
                  v-if="KindTypeIcon[content.textKind]"
                  :src="KindTypeIcon[content.textKind]"
                  class="timeline-avatar"
                  contain
                />
                <span
                  v-else
                  class="avatar-icon-download mdi mdi-download"
                />
              </v-avatar>
            </template>
            <template
              v-else
              v-slot:icon
            >
              <v-icon
                color="white"
                left
                style="margin: 0"
              >
                mdi mdi-clock-time-five
              </v-icon>
            </template>
            <span
              v-if="contentType === ContentType.SAVED_CONTENT"
              slot="opposite"
            >
              <span class="createdAt">
                {{ $t('content-creator.created-on') }}:</span>
              <p class="createdAt-content">{{ formatDateWithWeekdayName(content.createdAt, $i18n.locale) }}</p>
            </span>
            <span
              v-else
              slot="opposite"
            >
              <span class="createdAt">
                {{ $t('content-creator.scheduled-for') }}:
              </span>
              <p class="createdAt-content scheduled-for">
                {{ formatDateWithWeekdayName(content.socialMediaPosts[0].scheduledAt, $i18n.locale) }}
              </p>
            </span>

            <v-card class="elevation-2">
              <div class="d-md-flex flex-md-no-wrap justify-md-space-between">
                <div>
                  <v-card-title class="text-h5 px-2 px-sm-4 pb-0 pb-sm-4">
                    {{ $t(`content-creator.text-kind-types.${transformToKebabCase(content.textKind)}`) }}
                  </v-card-title>
                  <v-tooltip
                    content-class="tooltip-class"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-card-text class="pa-2 pa-sm-4">
                          {{ textTruncate(content.text, 150) }}
                        </v-card-text>
                      </span>
                    </template>

                    <span class="d-flex justify-space-between">
                      <v-alert
                        class="mt-5 alert"
                        icon="mdi-information-slab-circle"
                        type="info"
                        color="primary"
                      >
                        {{ content.text }}
                      </v-alert>
                    </span>
                  </v-tooltip>
                </div>
                <v-img
                  v-if="content.imagePath"
                  class="ma-2 ma-sm-4 w-full"
                  :src="content.imagePath"
                  max-width="175"
                  aspect-ratio="1"
                  style="border-radius: 5px; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);"
                />
              </div>
              <v-card-text class="pt-2 px-2 px-sm-4 d-flex flex-column flex-md-row gap-05">
                <div
                  v-if="content.downloaded || content.socialMediaPosts?.length > 0"
                  class="mr-md-2 d-md-flex align-center"
                >
                  <span
                    v-if="contentType === ContentType.SAVED_CONTENT"
                    class="mr-1"
                  >
                    <span v-if="content.downloaded || content.socialMediaPosts?.length > 0">{{ $t('content-creator.table.labels.posted-on') }}:</span>
                  </span>
                  <span
                    v-else
                    class="mr-1"
                  >
                    {{ $t('content-creator.table.labels.scheduled-for') }}:
                  </span>
                  <v-tooltip
                    v-for="({type, permalink, externalPostId}) in content.socialMediaPosts"
                    :key="externalPostId"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <a
                        href="javascript:void(0);"
                        @click="onSocialMediaIconClick(permalink)"
                      >
                        <v-avatar
                          :size="$vuetify.breakpoint.smAndDown ? 20 : 25"
                          class="mr-1 mr-md-2 mb-1 mb-sm-0"
                          tile
                          v-on="on"
                        ><v-img :src="KindTypeIcon[TypeToKind[type]]" />
                        </v-avatar>
                      </a>
                    </template>
                    <span class="mr-1 mr-sm-2">{{ KindName[TypeToKind[type]] }}</span>
                  </v-tooltip>

                  <v-tooltip
                    v-if="content.downloaded"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        size="25"
                        color="white"
                        class="square-icon"
                        v-on="on"
                      >
                        mdi-download
                      </v-icon>
                    </template>
                    <span class="mr-1 mr-sm-2">{{ $t('content-creator.table.labels.downloaded') }}</span>
                  </v-tooltip>
                </div>

                <div v-if="isMobile">
                  <span>
                    {{ $t('content-creator.scheduled-for-date') }}:
                  </span>
                  <p class="createdAt-content scheduled-for pa-0 ma-0">
                    {{ formatDateWithWeekdayName(contentType === ContentType.SCHEDULED_CONTENT
                      ? content.socialMediaPosts[0].scheduledAt
                      : content.createdAt, $i18n.locale) }}
                  </p>
                </div>
                <v-spacer />
                <DownloadPost
                  v-if="contentType === ContentType.SAVED_CONTENT"
                  :class="{ 'col-12': $vuetify.breakpoint.smAndDown }"
                  :saved-content-id="content.id"
                  :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 8 + 'px' : ''}"
                  :btn-class="'download-post-btn-in-history'"
                  :text="content.text"
                  :image="content.imagePath"
                  :image-source="content.imageSource"
                  :text-kind="content.textKind"
                  :topic="content.slug"
                  :topic-id="content.topicId"
                  :url="content.url"
                  :disabled="disabled"
                  @contentSaved="$emit('contentSaved')"
                />
                <v-btn
                  v-else
                  class="delete-post-btn"
                  :class="{ 'col-12 mb-2': $vuetify.breakpoint.smAndDown }"
                  outlined
                  elevation="0"
                  :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 8 + 'px' : '' }"
                  @click="deletePostDialog(content)"
                >
                  <v-icon
                    class="mr-2"
                    size="14"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                  {{ $t('buttons.content-creator.scheduled-post.delete') }}
                </v-btn>
                <SocialMediaSharing
                  :content="{
                    ...content,
                    image: content.imagePath,
                  }"
                  is-update
                  :content-type="contentType"
                  @contentSaved="$emit('contentSaved')"
                  @savedScheduledContent="$emit('savedScheduledContent')"
                >
                  <template v-slot:button="{ on, attrs }">
                    <v-btn
                      color="primary"
                      :class="{ 'col-12 mb-2': $vuetify.breakpoint.smAndDown }"
                      outlined
                      :disabled="disabled"
                      elevation="0"
                      :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 8 + 'px' : ''}"
                      v-bind="attrs"
                      v-on="on"
                      @click="trackPostDialog"
                    >
                      <template v-if="contentType === ContentType.SAVED_CONTENT">
                        <v-icon
                          class="mr-2"
                          size="14"
                        >
                          mdi-share-variant
                        </v-icon>
                        <span>{{ $t('buttons.content-creator.history.share') }}</span>
                      </template>
                      <template v-else>
                        <v-icon
                          class="mr-2"
                          size="14"
                        >
                          mdi-calendar-month-outline
                        </v-icon>
                        <span>{{ $t('buttons.content-creator.scheduled-post.reschedule') }}</span>
                      </template>
                    </v-btn>
                  </template>
                </SocialMediaSharing>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </BlurredBackdropUpselling>
    </v-tab-item>
  </div>
</template>

<script>
import { textTruncate } from '@/lib/truncate'
import { PostType } from '@/modules/contentCreator/creator/enums/PostType'
import { KindName, KindTypeIcon, TypeToKind } from '@/modules/contentCreator/creator/enums/KindType'
import BlurredBackdropUpselling from '@/components/BlurredBackdropUpselling.vue'
import DownloadPost from './DownloadPost.vue'
import { transformToKebabCase } from '@/lib/kebabCase'
import SocialMediaSharing from './SocialMedia/SocialMediaSharing.vue'
import DeleteScheduledPost from '@/modules/contentCreator/creator/SocialMedia/DeleteScheduledPost.vue'
import { ContentType } from '@/modules/contentCreator/creator/enums/ContentType'
import { formatDateWithWeekdayName } from '@/modules/contentCreator/creator/lib/date'

export default {
  components: { DeleteScheduledPost, DownloadPost, BlurredBackdropUpselling, SocialMediaSharing },
  props: {
    contentType: {
      type: String,
      default: ''
    },
    savedContents: {
      type: Array,
      default: () => []
    },
    filterValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      deleteScheduledPostDialog: false,
      contentForDelete: null,
      KindName,
      KindTypeIcon,
      PostType,
      socialMediaConnections: null,
      showSocialMediaDialog: false,
      selectedContentForPosting: {},
      TypeToKind
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    ContentType () {
      return ContentType
    },
    cssProps () {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary
      }
    }
  },
  methods: {
    transformToKebabCase,
    formatDateWithWeekdayName,
    deletePostDialog (content) {
      this.deleteScheduledPostDialog = true
      this.contentForDelete = content
    },
    onSocialMediaIconClick (permalink) {
      if (permalink) {
        window.open(permalink, '_blank')
      }
      this.$tracking.event('Content History', 'Clicked', 'Link-to-Post')
    },
    textTruncate,
    trackPostDialog () {
      this.$tracking.event('Content History', 'Clicked', 'Content-Repurpose')
    }
  }
}
</script>

<style scoped>
.square-icon {
  width: 25px; /* Ensure the width matches the size prop */
  height: 25px; /* Ensure the height matches the size prop */
  border-radius: 5px; /* Adjust this value for the desired roundness */
  background-color: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-timeline-item {
  margin-left: 200px;
  margin-right: 200px;
}

@media (min-width: 962px) and (max-width: 1740px) {
  .v-timeline-item {
    margin-left: 60px;
    margin-right: 60px;
  }
}
@media (min-width: 0) and (max-width: 1540px) {
  .v-timeline-item {
    margin-left: 0;
    margin-right: 0;
  }
}

.timeline-avatar {
  background-color: white;
}

.createdAt {
  color: #99A1B7;
  font-weight: 600;
}

.createdAt-content {
  color: #071437;
  font-weight: 500;
}

.table-row {
  border-bottom: 1px solid #e0e0e0;
}

.pointer {
  cursor: pointer;
}

.tooltip-class {
  background-color: transparent;
  max-width: 500px;
}

@media (max-width: 600px) {
  .tooltip-class {
    max-width: 100%;
  }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}

.th-downloaded {
  width: 50px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  padding: 0 8px;
  font-size: 10px;
}

.avatar-icon-download {
  color: white;
  font-size: 22px;
}
::v-deep .v-timeline-item__dot {
  width: 48px;
  height: 48px;
}

::v-deep .v-timeline-item__dot .v-timeline-item__inner-dot {
  margin: 0;
  width: 48px;
  height: 48px;
}

.scheduled-for {
  color: var(--primary-color);
}

.delete-post-btn {
  padding: 8px !important;
}

@media (max-width: 600px) {
  ::v-deep .v-timeline-item__divider {
    display: none !important;
  }
  ::v-deep .v-timeline--dense .v-timeline-item__body {
    max-width: 100% !important;
  }
}
</style>
